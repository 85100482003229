import cn from 'classnames'

import { Image } from 'ui/image'
import { Button } from 'ui/button'

import { ExternalLink } from 'components/external-link'
import { ExposureTracker } from 'components/exposure-tracker'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { APP_STORE_LINK, GOOGLE_PLAYSTORE_LINK, MOBILE_APP_DOWNLOAD_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import ItineraryDoodle from 'brand-assets/illustrations/itinerary-doodle.svg'
import DownloadAppStore from 'brand-assets/illustrations/download-app-store.svg'
import DownloadGooglePlaystore from 'brand-assets/illustrations/download-google-playstore.svg'

import s from './styles.module.scss'

type OngoingAppBannerProps = {
  trackEvent: TrackEventType
  noBorderRadius?: boolean
  trackEventAttributeId?: string
}

const OngoingAppBanner = ({ trackEvent, noBorderRadius, trackEventAttributeId }: OngoingAppBannerProps) => {
  const {
    isMobileView,
    globalArgs: { appTouchpointsData },
  } = useGlobalContext()
  const { header } = appTouchpointsData
  const { t } = useTranslation('common')

  const handleClickDownloadApp = (id?: string) => () => {
    trackEvent({
      attributeId: trackEventAttributeId || EVENTS.ONGOING_DEALS_MOBILE_APP,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: id
        ? {
            id,
          }
        : undefined,
    })
  }

  return (
    <div className={s.ongoingAppBannerContainer}>
      <div className={cn(s.ongoingAppBanner, { [s.noBorderRadius]: noBorderRadius })}>
        <div className={s.appMockup}>
          <Image src={{ url: '/app-mockup.png' }} alt="pelago mobile app" layout="fill" />
        </div>
        <ItineraryDoodle className={s.illustration} />
        <div className={s.content}>
          <h3 className={s.title} dangerouslySetInnerHTML={{ __html: header }}></h3>
          <div className={s.footer}>
            {isMobileView ? (
              <ExternalLink href={MOBILE_APP_DOWNLOAD_LINK}>
                <Button onClick={handleClickDownloadApp()} variant="primary-neon" size="small">
                  {t('action.downloadNow')}
                </Button>
              </ExternalLink>
            ) : (
              <>
                <ExternalLink onClick={handleClickDownloadApp('app-store')} href={APP_STORE_LINK}>
                  <DownloadAppStore />
                </ExternalLink>
                <ExternalLink onClick={handleClickDownloadApp('play-store')} href={GOOGLE_PLAYSTORE_LINK}>
                  <DownloadGooglePlaystore />
                </ExternalLink>
              </>
            )}
          </div>
        </div>
      </div>
      <ExposureTracker
        onExposure={() => {
          trackEvent({
            attributeId: trackEventAttributeId || EVENTS.ONGOING_DEALS_MOBILE_APP,
            attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
    </div>
  )
}

export { OngoingAppBanner }
